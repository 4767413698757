<template>
  <div class="page-home">
    <div class="p-card fix">
      <h4>公众号整体情况</h4>
      <ul class="msg-all">
        <li><span><i class="icon iconfont icon-messge" />新消息</span><span class="desc">0</span></li>
        <li><span><i class="icon iconfont icon-growth" />新增人数</span><span class="desc">0</span></li>
        <li><span><i class="icon iconfont icon-user" />总用户数</span><span class="desc">{{ users }}</span></li>
      </ul>
    </div>
    <div class="p-card">
      <form class="p-search-bar" @submit.prevent="onSubmit">
        <span>公众号：</span>
        <yos-input
          v-model.trim="keyword"
          placeholder="搜索公众号名称"
        />
        <yos-button type="submit" class="yos-button-primary">搜索</yos-button>
        <yos-button type="reset" @click="onReset">重置</yos-button>
      </form>
      <table
        cellspacing="0"
        class="m-table"
      >
        <thead>
          <tr>
            <th>公众号</th>
            <th>新消息<i class="icon iconfont icon-sort" /></th>
            <th>新增人数<i class="icon iconfont icon-sort" /></th>
            <th>总用户数<i class="icon iconfont icon-sort" /></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in authorizers" :key="item.authorizerAppid">
              <td>{{ item.nickName }}</td>
              <td>0</td>
              <td>0</td>
              <td>{{ item.followedUsers }}</td>
          </tr>
        </tbody>
      </table>
      <yos-pager
        :baseUrl="baseUrl"
        :totalPage="totalPage"
        @change="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import YosPager from '../components/other/yos-pager'
import { reactive, ref, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { addClass, serialize } from '../util'
import { usePage, useServer } from '../hook'
import {
  getAuthorizersCount,
  getAuthorizers
} from '../data'

export default {
  components: {
    YosPager
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const tabNav = ref([{
      name: '公众号列表',
      to: '/authorizers'
    }])
    const state = reactive({
      authorizers: [],
      totalPage: 0,
      keyword: route.query.keyword || '',
      status: -1,
      users: 0
    })
    const url = '/'

    const { page, baseUrl } = usePage(url, {keyword: state.keyword})

    const setAuthorizersCountParams = useServer(getAuthorizersCount, ({ users }) => {
      state.users = users
    })

    const setAuthorizersParams = useServer(getAuthorizers, ({ authorizers, totalPage }) => {
      state.authorizers = authorizers
      state.totalPage = totalPage
    })

    const onSubmit = () => {
      const params = {keyword: state.keyword, status: state.status, page: 1}
      router.replace(serialize(url, params))
      setAuthorizersParams(params)
    }

    const onReset = () => {
      state.keyword = ''
      onSubmit()
    }

    const onPageChange = (page) => {
      setAuthorizersParams({keyword: state.keyword, status: state.status, page})
    }

    setAuthorizersCountParams({})
    setAuthorizersParams({keyword: state.keyword, status: state.status, page})

    return {
      ...toRefs(state),
      page,
      baseUrl,
      tabNav,
      addClass,
      onSubmit,
      onReset,
      onPageChange
    }
  }
}
</script>

<style lang="postcss">
.page-home {
  .icon {
    font-size: 18px;
    margin: 3px 10px 3px 5px;
    color: #999;
    vertical-align: -1px;
  }

  .p-list {
    margin-top: 20px;
  }

  .desc {
    padding-top: 5px;
    font-size: 24px;
  }

  .code_mod h4 {
    font-weight: 400;
  }

  .p-card {
    margin-top: 30px;

    h4 {
      font-weight: 400;
      font-size: 20px;
      margin-bottom: 15px;
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 0;
      padding: 0;
    }
  }

  .msg-all li {
    width: 33%;
    height: 120px;
    text-align: center;
    float: left;
    padding-top: 28px;
  }

  .msg-all li span {
    display: block;
  }
}
</style>
